/* You can add global styles to this file, and also import other style files */
@import './assets/css/grid.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-form-field{ width: 100%; }

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: white;
  animation: spinner .8s linear infinite;
}

.img-responsive{
  width: 100%;
  height: auto;
}

.mt-30{
  margin-top: 30px;
}
